import React, { useState } from "react";
import './App.css';
import data from "./data.json";

function App() {
  const [currentIndex, setCurrentIndex] = useState(Math.floor(Math.random() * data.length));
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [feedback, setFeedback] = useState("");

  const item = data[currentIndex];

  const toggleAnswer = (answer) => {
    if (item.multiple_choice) {
      setSelectedAnswers(prev =>
          prev.includes(answer)
              ? prev.filter(ans => ans !== answer)
              : [...prev, answer]
      );
    } else {
      setSelectedAnswers([answer]);
    }
  };

  const checkAnswer = () => {
    const isCorrect = selectedAnswers.every(ans =>
        item.answers.some(a => a.answer === ans && a.correct)
    ) && selectedAnswers.length === item.answers.filter(a => a.correct).length;

    setFeedback(isCorrect ? "Correct!" : "Wrong answer!");
    setTimeout(() => {
      setCurrentIndex(Math.floor(Math.random() * data.length));
      setSelectedAnswers([]);
      setFeedback("");
    }, 1000);
  };

  return (
      <div className="App">
        <h1 className={"question"}>{item.questions} <br/> <p>{item.multiple_choice ? "(Több válasz lehetséges)" : "(Egy válasz lehetséges)"}</p></h1>
        {feedback && <p className="feedback-text" style={{color: feedback === "Wrong answer!" ? "#d32f2f" : "#4caf50" }}>{feedback}</p>}
        <div className="answers-grid">
          {item.answers.map((ans, index) => (
              <button
                  key={index}
                  className={`answer-btn ${selectedAnswers.includes(ans.answer) ? 'selected' : ''}`}
                  onClick={() => toggleAnswer(ans.answer)}
              >
                {ans.answer}
              </button>
          ))}
        </div>
        <button onClick={checkAnswer} className="ok-btn">OK</button>
      </div>
  );
}

export default App;
